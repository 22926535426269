import type { AppProps } from "next/app";
import Script from "next/script";
import { useRouter } from "next/router";
import { roboto, rubik } from "@/utils/fonts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.css";

import { toastContextProviderConfig } from "@myevaluations/myevals-plasmic-utils";
import { PlasmicRootProvider } from "@plasmicapp/react-web";
import Toast from "@/src/plasmic-components/Toast";
import GlobalContextsProvider from "@/src/plasmic-components/plasmic/my_evals_marketing/PlasmicGlobalContextsProvider";
toastContextProviderConfig.toastRenderer = Toast;

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const isPlasmicHost = router.pathname === "/m/plasmic-host";

  return (
    <PlasmicRootProvider>
      {!isPlasmicHost && (
        <>
          <Script
            id="hs-script-loader"
            src="//js.hs-scripts.com/44358990.js"
            async
            defer
          />
          <Script
            id="google-analytics"
            src="https://www.googletagmanager.com/gtag/js?id=G-F2D1J4HGR6"
            async
            defer
          />
          <Script id="google-analytics-init">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-F2D1J4HGR6');
            `}
          </Script>
        </>
      )}
      <style jsx global>{`
        html {
          --rubik: ${rubik.style.fontFamily};
          --roboto: ${roboto.style.fontFamily};
        }
      `}</style>
      <GlobalContextsProvider>
        <Component {...pageProps} />
      </GlobalContextsProvider>
    </PlasmicRootProvider>
  );
}
